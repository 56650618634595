import { Interval } from '../../data/interval';
import { Direction, IPosition, Position } from '../../data/position';
import { PriorityValue } from '../../data/priority.value';
import { ICrossword, ICrosswordCWQuestion, ICrosswordSize } from '../../model/crossword';
import { CWQuestionType, ICWQuestionBase, QuestionBasePriority } from '../../model/cwquestion';
import { AssertUtil } from '../../util/assert.util';
import { CWQuestionUtil, FilterCWQuestion } from '../../util/cwquestion.util';
import { PriorityScore, ScoreQuestionChar } from './priority.score';
import { CrosswordMatrixScore } from './statistics.util';

const MAX_QUESTIONS_LENGTH = 11;
const CountMaxCrossings = 5;
class SpecialCharacters {
  static readonly Any = 0;
  static readonly Question = 10000;
  static readonly End = 1000;

  static isStartOrEnd(c: number) {
    return c >= this.End;
  }
  static isSpecialCharacter(c: number) {
    return c == this.Any || c >= this.End;
  }
  static isOpenForQuestion(c: number) {
    return c == this.Any || (c >= this.End && c < this.Question);
  }
  static isAny(c: number) {
    return c == this.Any;
  }
}
export class BuildQuestion implements QuestionBasePriority {
  readonly priority: PriorityValue;
  readonly indexId: number;
  readonly question: string;
  readonly answer: string;
  readonly chars: number[];
  readonly score: ScoreQuestionChar;
  readonly type: CWQuestionType;
  readonly imageId?: string;
  constructor(q: QuestionBasePriority) {
    this.indexId = q.indexId;
    this.priority = q.priority;
    this.question = q.question;
    this.answer = q.answer;
    this.chars = [SpecialCharacters.Question, ...this.answer.split('').map((c) => c.toLowerCase().charCodeAt(0)), SpecialCharacters.End];
    this.score = PriorityScore.getScoreForQuestionBase(this);
    this.type = q.type;
    this.imageId = q.imageId;
  }
}
class QuestionPosition {
  constructor(readonly question: BuildQuestion, readonly position: Position) {}
  toCrosswordQuestion(): ICrosswordCWQuestion {
    return { cwQuestion: this.toBaseQuestion(), position: this.toIPosition(), priority: this.question.priority };
  }
  toInterval() {
    return Interval.fromPosition(this.position, this.question.chars.length);
  }
  copy() {
    return new QuestionPosition(this.question, this.position);
  }
  private toBaseQuestion(): ICWQuestionBase {
    return {
      answer: this.question.answer,
      indexId: this.question.indexId,
      question: this.question.question,
      type: this.question.type,
      imageId: this.question.imageId,
    };
  }
  private toIPosition(): IPosition {
    return { x: this.position.x, y: this.position.y, direction: this.position.direction };
  }
}
class AddQuestion {
  public static getAddChar(currentChar: number, newChar: number, i: number, length: number) {
    if (i == 0) {
      return AddQuestion.getQuestionChar(currentChar);
    }
    if (i == length - 1) {
      return AddQuestion.getEndChar(currentChar);
    }
    return AddQuestion.getChar(currentChar, newChar);
  }
  static getChar(currentC: number, newC: number) {
    if (currentC == SpecialCharacters.Any) {
      return newC;
    } else {
      AssertUtil.assert(() => currentC == newC);
      return -newC;
    }
  }
  static getQuestionChar(c: number) {
    return c + SpecialCharacters.Question;
  }
  static getEndChar(c: number) {
    return c + SpecialCharacters.End;
  }
}
class RemoveQuestion {
  public static getRemoveChar(currentChar: number, i: number, length: number) {
    if (i == 0) {
      return this.getQuestionChar(currentChar);
    }
    if (i == length - 1) {
      return this.getEndChar(currentChar);
    }
    return this.getChar(currentChar);
  }
  static getChar(currentC: number) {
    if (currentC > 0) {
      return SpecialCharacters.Any;
    } else {
      return Math.abs(-currentC);
    }
  }
  static getQuestionChar(c: number) {
    return c - SpecialCharacters.Question;
  }
  static getEndChar(c: number) {
    return c - SpecialCharacters.End;
  }
}
export class OpenInterval {
  readonly interval: Interval;
  private readonly chars: number[] = [];

  constructor(position: Position, charsTmp: number[]) {
    const char = charsTmp.shift();
    if (char != null) {
      this.chars.push(char);
    }
    for (const c of charsTmp) {
      this.chars.push(Math.abs(c));
      if (SpecialCharacters.isStartOrEnd(c)) {
        break;
      }
    }
    this.interval = new Interval(position.x, position.y, position.direction, this.chars.length);
  }
  canAddQuestion(question: BuildQuestion) {
    return this.canContainInLength(question) && this.canContainQuestion(question);
  }
  getCrossings(question: BuildQuestion) {
    let crossings = 0;
    for (let i = 0; i < question.chars.length - 1; i++) {
      if (!SpecialCharacters.isSpecialCharacter(this.chars[i])) {
        crossings++;
      }
    }
    return crossings;
  }
  getBitFilter(): FilterCWQuestion {
    const filter: FilterCWQuestion = { regExFilter: '', lengths: [] };
    for (let i = 1; i < this.chars.length - 1; i++) {
      filter.regExFilter += SpecialCharacters.isAny(this.chars[i]) ? '.' : String.fromCharCode(this.chars[i]);
      if (SpecialCharacters.isSpecialCharacter(this.chars[i + 1])) {
        filter.lengths.push(i);
      }
    }
    return filter;
  }

  private canContainQuestion(q: BuildQuestion) {
    if (!SpecialCharacters.isSpecialCharacter(this.chars[q.chars.length - 1])) {
      return false;
    }
    for (let i = 1; i < q.chars.length - 1; i++) {
      if (this.chars[i] != SpecialCharacters.Any && this.chars[i] != q.chars[i]) {
        return false;
      }
    }
    return true;
  }
  private canContainInLength(question: BuildQuestion) {
    return question.chars.length <= this.interval.length;
  }
}
class MatrixContainer {
  private readonly matrix: number[][];

  public constructor(private readonly size: ICrosswordSize, matrix?: number[][]) {
    this.matrix = matrix ? matrix : MatrixContainer.createEmptyMatrix(size.mainAxis + 1, size.crossAxis + 1);
  }
  get(position: IPosition, length: number) {
    if (position.direction == Direction.MAINAXIS) {
      const xArray = this.matrix.slice(position.x, position.x + length);
      return xArray.map((yArray) => yArray[position.y]);
    } else {
      return this.matrix[position.x].slice(position.y, position.y + length);
    }
  }
  getUntilEnd(position: IPosition) {
    if (position.direction == Direction.MAINAXIS) {
      const xArray = this.matrix.slice(position.x);
      return xArray.map((yArray) => yArray[position.y]);
    } else {
      const yArray = this.matrix[position.x];
      return yArray.slice(position.y);
    }
  }
  getCrossings(qp: QuestionPosition) {
    return this.get(qp.position, qp.question.chars.length).filter((c) => c < 0).length;
  }
  getOpenPositions() {
    const positions: Position[] = [];
    for (let x: number = 0; x < this.size.mainAxis; x++) {
      for (let y: number = 0; y < this.size.crossAxis; y++) {
        if (SpecialCharacters.isOpenForQuestion(this.matrix[x][y])) {
          if (x > 0 && y < this.size.crossAxis - 1 && !SpecialCharacters.isStartOrEnd(this.matrix[x][y + 1])) {
            positions.push(new Position(x, y, Direction.CROSSAXIS));
          }
          if (y > 0 && x < this.size.mainAxis - 1 && !SpecialCharacters.isStartOrEnd(this.matrix[x + 1][y])) {
            positions.push(new Position(x, y, Direction.MAINAXIS));
          }
        }
      }
    }
    return positions;
  }
  getOpenPositionsForPositions(positions: Position[]) {
    return positions.filter((p) => this.isAnOpenPosition(p.x, p.y, p.direction));
  }
  setQuestion(position: Position, question: BuildQuestion) {
    const chars = question.chars;
    const positions = position.getSequence(chars.length);
    positions.forEach((p, i) => (this.matrix[p.x][p.y] = AddQuestion.getAddChar(this.matrix[p.x][p.y], chars[i], i, chars.length)));
  }
  removeQuestion(position: Position, question: BuildQuestion) {
    const chars = question.chars;
    const positions = position.getSequence(chars.length);
    positions.forEach((p, i) => (this.matrix[p.x][p.y] = RemoveQuestion.getRemoveChar(this.matrix[p.x][p.y], i, chars.length)));
  }
  copy() {
    return new MatrixContainer(this.size, this.copyMatrix());
  }
  private isAnOpenPosition(x: number, y: number, direction: Direction): boolean {
    if (SpecialCharacters.isOpenForQuestion(this.matrix[x][y])) {
      if (
        direction == Direction.CROSSAXIS &&
        x > 0 &&
        y < this.size.crossAxis - 1 &&
        !SpecialCharacters.isStartOrEnd(this.matrix[x][y + 1])
      ) {
        return true;
      }
      if (
        direction == Direction.MAINAXIS &&
        y > 0 &&
        x < this.size.mainAxis - 1 &&
        !SpecialCharacters.isStartOrEnd(this.matrix[x + 1][y])
      ) {
        return true;
      }
    }
    return false;
  }
  private copyMatrix(): number[][] {
    const matrix = MatrixContainer.createEmptyMatrix(this.size.mainAxis + 1, this.size.crossAxis + 1);
    for (let x: number = 0; x < this.matrix.length; x++) {
      for (let y: number = 0; y < this.matrix[x].length; y++) {
        matrix[x][y] = this.matrix[x][y];
      }
    }
    return matrix;
  }
  private static createEmptyMatrix(hSize: number, vSize: number) {
    const matrix = Array(hSize)
      .fill(null)
      .map(() => Array(vSize).fill(SpecialCharacters.Any));

    //Fill last vertical char and last horizontal char with end char
    matrix[hSize - 1].fill(SpecialCharacters.End);
    matrix.forEach((row) => (row[vSize - 1] = SpecialCharacters.End));

    return matrix;
  }
}
export class BuildMatrix {
  private readonly questionPositions: Map<number, QuestionPosition>;
  private readonly matrixContainer: MatrixContainer;
  private openIntervals: OpenInterval[];
  private score: CrosswordMatrixScore;
  constructor(private readonly size: ICrosswordSize, matrixContainer?: MatrixContainer, questionPositions?: Map<number, QuestionPosition>) {
    this.matrixContainer = matrixContainer ? matrixContainer : new MatrixContainer(size);
    this.questionPositions = questionPositions ? questionPositions : new Map<number, QuestionPosition>();
    this.openIntervals = this.getOpenIntervalsFromMatrix();
  }
  getCrossingsForQuestion(indexId: number) {
    const q = this.questionPositions.get(indexId);
    if (q) {
      return this.matrixContainer.getCrossings(q);
    }
    return null;
  }
  addQuestion(position: Position, question: BuildQuestion) {
    const qp = new QuestionPosition(question, position);
    this.questionPositions.set(question.indexId, qp);
    this.matrixContainer.setQuestion(position, question);
    this.openIntervals = this.getOpenIntervalsAfterAddingOrRemovingQuestion(qp, false);
    this.score = null;
  }
  compareOpenIntervals(openIntervals: OpenInterval[], openIntervalsIntersecting: OpenInterval[]) {
    const openIntervalsIntersectingMissing = openIntervals.filter(
      (oi) => !openIntervalsIntersecting.find((oiIntersecting) => oiIntersecting.interval.equalInterval(oi.interval))
    );
    const openIntervalsFromScratchMissing = openIntervalsIntersecting.filter(
      (oi) => !openIntervals.find((oiNew) => oiNew.interval.equalInterval(oi.interval))
    );
    if (openIntervalsIntersectingMissing.length > 0 || openIntervalsFromScratchMissing.length > 0) {
      console.log('Open intervals is not equal, missing intersecting', openIntervalsIntersectingMissing);
      console.log('Open intervals is not equal, missing from scratch', openIntervalsFromScratchMissing);
    }
  }
  removeQuestion(indexId: number) {
    const qp = this.questionPositions.get(indexId);
    if (qp) {
      this.matrixContainer.removeQuestion(qp.position, qp.question);
      this.questionPositions.delete(indexId);
      this.openIntervals = this.getOpenIntervalsAfterAddingOrRemovingQuestion(qp, true);
      this.score = null;
    }
    return qp;
  }
  copy() {
    return new BuildMatrix(this.size, this.matrixContainer.copy(), this.getCopyOfPositions());
  }

  getQuestionIds() {
    return this.questionPositions.keys();
  }
  getQuestions() {
    return [...this.questionPositions.values()].map((q) => q.question);
  }
  getCrossword() {
    const crosswordQuestions = [...this.questionPositions.values()].map((q) => q.toCrosswordQuestion());
    return { size: this.size, crosswordQuestions: crosswordQuestions } as ICrossword;
  }
  getScore() {
    if (!this.score) {
      this.score = this.getScoreInernal();
    }
    return this.score;
  }
  getOpenIntervals() {
    return this.openIntervals;
  }

  isEqual(matrix: BuildMatrix) {
    if (this.questionPositions.size != matrix.questionPositions.size) {
      return false;
    }
    for (const qp of this.questionPositions.values()) {
      const qp2 = matrix.questionPositions.get(qp.question.indexId);
      if (!qp2 || !qp2.position.equal(qp.position)) {
        return false;
      }
    }
    return true;
  }
  static createFromCrossword(crossword: ICrossword) {
    const matrix = new BuildMatrix(crossword.size);
    crossword.crosswordQuestions.forEach((q) =>
      matrix.addQuestion(Position.toPosition(q.position), new BuildQuestion(CWQuestionUtil.getPriorityQuestion(q)))
    );
    return matrix;
  }
  private getCopyOfPositions() {
    return new Map<number, QuestionPosition>(
      [...this.questionPositions.entries()].map((entry) => {
        return [entry[0], entry[1]];
      })
    );
  }
  private getOpenIntervalsFromMatrix() {
    return this.matrixContainer.getOpenPositions().map((p) => new OpenInterval(p, this.matrixContainer.getUntilEnd(p)));
  }
  private getOpenIntervalsAfterAddingOrRemovingQuestion(qp: QuestionPosition, refreshQuestionsPositions: boolean) {
    const questionPositionInterval = qp.toInterval();
    const openIntervals = this.getOpenIntervals();
    const newOpenIntervals: OpenInterval[] = [];
    const refreshPositions: Position[] = [];
    for (const openInterval of openIntervals) {
      if (!openInterval.interval.doesIntervalIntersect(questionPositionInterval)) {
        newOpenIntervals.push(openInterval);
      } else {
        refreshPositions.push(openInterval.interval);
      }
    }
    if (refreshQuestionsPositions) {
      const refreshInterval = questionPositionInterval.subtractLength(1);
      refreshPositions.push(...refreshInterval.toPositions()); //-1 because of the end character which is open for questions already
      if (questionPositionInterval.x > 0) {
        refreshPositions.push(questionPositionInterval.addX(-1));
      }
      if (questionPositionInterval.y > 0) {
        refreshPositions.push(questionPositionInterval.addY(-1));
      }
    }
    const openPositionsForRefresh = this.matrixContainer.getOpenPositionsForPositions(refreshPositions);
    const openIntervalsForRefresh = openPositionsForRefresh.map(
      (p) => new OpenInterval(p, this.matrixContainer.get(p, MAX_QUESTIONS_LENGTH))
    );
    return [...newOpenIntervals, ...openIntervalsForRefresh];
  }
  private getScoreInernal() {
    let scoreResult = 0;
    let priorityScoreResult = 0;
    const score = { perCrossing: 100, perChar: 1 };
    for (const qp of this.questionPositions.values()) {
      const priorityScore = qp.question.score;
      const crossings = Math.min(this.matrixContainer.getCrossings(qp), CountMaxCrossings);

      priorityScoreResult += crossings * priorityScore.perCrossing + qp.question.answer.length * priorityScore.perChar;
      scoreResult += crossings * score.perCrossing + qp.question.answer.length * score.perChar;
    }
    return new CrosswordMatrixScore(priorityScoreResult, scoreResult);
  }
}
