<h4 mat-dialog-title style="text-align: center">
  {{ (editQuestionData.cwQuestion._id ? 'titles.update-question' : 'titles.create-question') | translate }}
</h4>
<form [formGroup]="cwQuestionForm" novalidate>
  <mat-dialog-content>
    <div fxLayout="column" fxLayoutGap="5px">
      <div fxLayout="row" fxLayoutGap="5px" fxlayoutAlign="center center">
        <mat-form-field fxFlex test-id="enter-question-form-field">
          <mat-label>{{ 'labels.enter-question' | translate }}</mat-label>
          <input test-id="enter-question" matInput formControlName="question" autocomplete="off" />
          @if (cwQuestionForm.controls.question.hasError('required')) {
            <mat-error>{{ 'errors.question-missing' | translate }}</mat-error>
          }
          @if (cwQuestionForm.controls.question.hasError('maxlength')) {
            <mat-error>{{ 'errors.question-to-long' | translate }}</mat-error>
          }
        </mat-form-field>
        @let cwQuestionFormValue = cwQuestionForm.value;
        @if (cwQuestionForm.value.imageId || cwQuestionForm.value.imageUrl) {
          <div class="image-container">
            <img
              fxFlexFill
              [src]="cwQuestionFormValue.imageId ? (cwQuestionFormValue.imageId | imageUrl) : cwQuestionFormValue.imageUrl"
              [alt]="'errors.missing-image' | translate"
            />
          </div>
        }
      </div>
      <div style="padding-bottom: 10px">
        <span>{{ 'labels.enter-answer' | translate }}</span>
        <app-edit-question-tiles [editQuestionData]="editQuestionTiles" [answer]="cwQuestionForm.controls.answer"></app-edit-question-tiles>
        @if (cwQuestionForm.controls.answer.hasError('required')) {
          <mat-error>{{ 'errors.answer-missing' | translate }}</mat-error>
        }
        @if (cwQuestionForm.controls.answer.hasError('maxlength')) {
          <mat-error>{{ 'errors.answer-to-long' | translate }}</mat-error>
        }
        @if (cwQuestionForm.controls.answer.hasError('pattern') && !cwQuestionForm.controls.answer.hasError('required')) {
          <mat-error>{{ 'errors.answer-characters' | translate }}</mat-error>
        }
        @if (cwQuestionForm.controls.answer.hasError('notOneOfFilterLengths') && !cwQuestionForm.controls.answer.hasError('required')) {
          <mat-error>{{ 'errors.answer-not-one-of-filter-lengths' | translate }}</mat-error>
        }
      </div>
      <mat-form-field>
        <mat-label>{{ 'labels.cwquestion-category' | translate }}</mat-label>
        <mat-select formControlName="category" test-id="select-category">
          @for (category of CWQuestionCategories; track category) {
            <mat-option test-id="select-category-option" [value]="category.value">
              {{ category.label | translate }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
      <mat-expansion-panel [expanded]="false">
        <mat-expansion-panel-header>
          <mat-panel-title>{{ 'panel-titles.extra-question-data' | translate }}</mat-panel-title>
        </mat-expansion-panel-header>
        <div fxLayout="column" fxLayoutGap="5px">
          <mat-form-field>
            <mat-label>{{ 'labels.difficulty' | translate }}</mat-label>
            <mat-select formControlName="difficulty">
              @for (difficulty of Difficulties; track difficulty) {
                <mat-option [value]="difficulty.value">
                  {{ difficulty.label | translate }}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
          <app-cwquestion-tags [tags]="cwQuestionForm.controls.tags" [tagInput]="cwQuestionForm.controls.tagInput"></app-cwquestion-tags>
          <mat-form-field>
            <mat-label>{{ 'labels.cwquestion-visiblity' | translate }}</mat-label>
            <mat-select formControlName="visibility">
              @for (visibility of CWQuestionVisiblities; track visibility) {
                <mat-option [value]="visibility.value">
                  {{ visibility.label | translate }}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
      </mat-expansion-panel>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions fxLayout="row">
    <app-flexable-button-menu fxFlex [buttons]="editQuestionButtons"> </app-flexable-button-menu>
  </mat-dialog-actions>
</form>
