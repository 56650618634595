<div fxLayout="row">
  @if (matrixTilesMain && rowTiles) {
    <div #showCrossword id="showCrossword" listenForResize (sizeChanged)="onSizeChanged($event)" fxFlexFill style="position: relative">
      <div
        fxFlexFill
        id="matrixContainer"
        [ngClass]="{ showing: showCrosswordAs == ShowCrosswordAs.Matrix, hidden: showCrosswordAs != ShowCrosswordAs.Matrix }"
      >
        <div
          fxLayout="row"
          [ngStyle]="{
            position: 'relative',
            width: '100%',
            'height.px': (matrixDirection == Direction.MAINAXIS ? crosswordSize.crossAxis : crosswordSize.mainAxis) * matrixTileSize,
          }"
        >
          @for (tileColumn of matrixDirection == Direction.MAINAXIS ? matrixTilesMain : matrixTilesCross; track tileColumn) {
            <div fxLayout="column">
              @for (tile of tileColumn; track tile.trackId) {
                <div fxLayout="row">
                  <app-crossword-tile-border
                    [tile]="tile"
                    [matrixDirection]="matrixDirection"
                    [tilesState]="tilesState"
                    [tileContainerType]="TileContainerType.Matrix"
                    [ngStyle]="{
                      'width.px': matrixTileSize,
                      'height.px': matrixTileSize,
                    }"
                  >
                    <app-crossword-tile
                      [tile]="tile"
                      [tileSize]="matrixTileSize"
                      [tilesState]="tilesState"
                      [matrixDirection]="matrixDirection"
                      [tileContainerType]="TileContainerType.Matrix"
                      [showCrosswordAs]="showCrosswordAs"
                      (tileEvent)="onMatrixTileEvent($event)"
                    ></app-crossword-tile>
                  </app-crossword-tile-border>
                </div>
              }
            </div>
          }
        </div>
      </div>
      <div
        id="rowContainer"
        [ngClass]="{ showing: showCrosswordAs == ShowCrosswordAs.List, hidden: showCrosswordAs != ShowCrosswordAs.List }"
        class="flex-column"
      >
        <mat-list>
          @for (row of rowTiles; track row) {
            <div class="flex-column" style="padding-bottom: 10px">
              <app-show-row-question [cwQuestion]="row.cwQuestion"></app-show-row-question>
              <div class="flex-row">
                @for (tile of row.tiles; track tile) {
                  <app-crossword-tile-border
                    [tile]="tile"
                    [tilesState]="tilesState"
                    [tileContainerType]="TileContainerType.Row"
                    [ngStyle]="{
                      'width.px': rowTileSize,
                      'height.px': rowTileSize,
                    }"
                  >
                    <app-crossword-tile
                      [tile]="tile"
                      [tileSize]="rowTileSize"
                      [tilesState]="tilesState"
                      [tileContainerType]="TileContainerType.Row"
                      [showCrosswordAs]="showCrosswordAs"
                      (tileEvent)="onRowTileEvent($event, row.tiles)"
                    ></app-crossword-tile
                  ></app-crossword-tile-border>
                }
              </div>
            </div>
          }
        </mat-list>
      </div>
      @if (showShowQuestionText) {
        @if (showShowQuestionText.question.type != CWQuestionType.Image) {
          <app-show-all-question
            [questionPosition]="showShowQuestionText.position"
            [question]="showShowQuestionText.question"
            [size]="matrixTileSize"
            [showCrosswordWidth]="tileContainerWidth | async"
            style="z-index: 100"
          ></app-show-all-question>
        }
        @if (showShowQuestionText.question.type == CWQuestionType.Image) {
          <app-show-all-image
            [questionPosition]="showShowQuestionText.position"
            [question]="showShowQuestionText.question"
            [size]="matrixTileSize"
            [showCrosswordWidth]="tileContainerWidth | async"
            [matrixDirection]="matrixDirection"
            [crosswordSize]="crosswordSize"
            style="z-index: 100"
          ></app-show-all-image>
        }
      }
    </div>
  }
</div>
