<div mat-dialog-title style="text-align: center">
  {{ 'titles.generate-image' | translate }}
</div>
<mat-dialog-content [formGroup]="generateImage">
  <div fxLayout="column" style="margin-bottom: 10px; margin-top: 5px" fxLayoutGap="10px">
    <div fxLayout="column" fxLayoutGap="5px" fxLayout.gt-md="row">
      <mat-form-field fxFlex>
        <mat-label>{{ 'labels.generate-image' | translate }}</mat-label>
        <input test-id="generate-images-input" matInput formControlName="generateText" (keyup.enter)="onGenerateImages()" />
      </mat-form-field>
      <button test-id="generate-images-button" mat-raised-button (click)="onGenerateImages()">
        {{ 'buttons.generate-image' | translate }}
      </button>
    </div>
    @if (generateImage.controls.imageUrl.value) {
      <div fxLayout="column" fxLayoutAlign="center center">
        <img
          test-id="image-search-result"
          style="object-fit: contain; width: 100%; aspect-ratio: 1/1"
          [src]="generateImage.controls.imageUrl.value"
          [alt]="'Image url:' + generateImage.controls.imageUrl.value"
        />
      </div>
    }
  </div>
</mat-dialog-content>
<mat-dialog-actions fxLayout="row" fxLayoutAlign="end">
  <button test-id="add-image-question" [disabled]="!generateImage.valid" mat-raised-button (click)="onSelectImage()">
    {{ 'buttons.ok' | translate }}
  </button>
  <button mat-raised-button (click)="onCancel()">{{ 'buttons.cancel' | translate }}</button>
</mat-dialog-actions>
