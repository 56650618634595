import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { lastValueFrom } from 'rxjs';
import { BuildCrosswordMatrix } from '../../../../../Common/builder/build.crossword.matrix';
import { BuildJobGenerateCrosswordData } from '../../../../../Common/data/generate.crossword.data';
import { PriorityValue } from '../../../../../Common/data/priority.value';
import { ICrossword } from '../../../../../Common/model/crossword';
import { GenerateRandomCrosswords } from '../../../../../Common/model/generate.random.crosswords';
import { CWQuestionUtil } from '../../../../../Common/util/cwquestion.util';
import { IndexPriorityUtil } from '../../../../../Common/util/index.priority.util';
import { StopWatch } from '../../../../../Common/util/stopwatch.util';
import { MessageBoxType } from '../../components/dialog/message-box-content/message-box-content.component';
import { DialogService } from '../../components/dialog/service/dialog.service';
import { CrosswordConstants } from '../../components/util/crossword.constants';
import { AppSettingsService } from '../appsettings/app-settings.service';
import { ConfigService } from '../config/config.service';
import { CWQuestionService } from '../cwquestion/cwquestion.service';
import { GenerateCrosswordWorkerService } from './generate.crossword.worker.service';

@Injectable({
  providedIn: 'root',
})
export class GenerateCrosswordService {
  constructor(
    private configService: ConfigService,
    private http: HttpClient,
    private appSettingsService: AppSettingsService,
    private cwquestionService: CWQuestionService,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private generateCrosswordWorkerService: GenerateCrosswordWorkerService,
  ) {}
  async generateCrosswordOnApp(buildJobGenerateCrosswordData: BuildJobGenerateCrosswordData) {
    if (!(await this.checkToManyThemeQuestionsInBuild(buildJobGenerateCrosswordData))) {
      return;
    }
    StopWatch.start(this);
    const questions = await this.cwquestionService.loadQuestionsWithIndexIds(
      IndexPriorityUtil.getIndexs(buildJobGenerateCrosswordData.questions),
    );

    const questionsWithPriority = CWQuestionUtil.getPriorityBaseQuestionFrom(questions, buildJobGenerateCrosswordData.questions);

    const settings = {
      ...buildJobGenerateCrosswordData,
      questions: questionsWithPriority,
    };
    const crossword = BuildCrosswordMatrix.build(settings);
    // const crossword = await this.generateCrosswordWorkerService.generateCrossword(settings);
    const time = StopWatch.stop(this);
    console.log('Generating crossword took:%sms', time);
    return crossword;
  }

  async generateCrosswordOnServer(buildJobGenerateCrosswordData: BuildJobGenerateCrosswordData) {
    StopWatch.start(this);

    if (!(await this.checkToManyThemeQuestionsInBuild(buildJobGenerateCrosswordData))) {
      return;
    }
    buildJobGenerateCrosswordData.questions = CWQuestionUtil.removeMoreQuestionsThanMaxPerPriority(
      buildJobGenerateCrosswordData.questions,
      CrosswordConstants.MaxNumberOfQuestionsPerBuild,
    );
    const routes = this.configService.getRoutes();

    const crossword = await lastValueFrom(
      this.http.post<ICrossword>(this.appSettingsService.appSettings.apiurl + routes.generateCrossword, buildJobGenerateCrosswordData),
    );
    const time = StopWatch.stop(this);
    console.log('Generating crossword took:%sms', time);

    return crossword;
  }

  async checkToManyThemeQuestionsInBuild(buildJobGenerateCrosswordData: BuildJobGenerateCrosswordData) {
    if (
      buildJobGenerateCrosswordData.questions.filter((q) => q.priority == PriorityValue.Theme).length >
      CrosswordConstants.MaxNumberOfThemeQuesions
    ) {
      await this.dialogService.showMessageBox(
        '',
        this.translateService.instant('messages.to-many-theme-questions-in-build', { max: CrosswordConstants.MaxNumberOfThemeQuesions }),
        MessageBoxType.Ok,
      );
      return false;
    }
    return true;
  }

  async generateRandomCrossword() {
    const routes = this.configService.getRoutes();
    await lastValueFrom(
      this.http.post(this.appSettingsService.appSettings.apiurl + routes.generateCrossword + '/generateRandomCrosswords', null),
    );
  }

  async getGenerateRandomCrosswordBuildIds() {
    const routes = this.configService.getRoutes();
    return await lastValueFrom(
      this.http.get<GenerateRandomCrosswords>(
        this.appSettingsService.appSettings.apiurl + routes.generateCrossword + '/getGenerateRandomCrosswords',
      ),
    );
  }
  async setGenerateRandomCrosswordBuildIds(buildIds: GenerateRandomCrosswords) {
    const routes = this.configService.getRoutes();
    await lastValueFrom(
      this.http.post(this.appSettingsService.appSettings.apiurl + routes.generateCrossword + '/setGenerateRandomCrosswords', buildIds),
    );
  }
}
