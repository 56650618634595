import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CrosswordListItem, CrosswordType } from '../../../../../../Common/model/crossword';
import { CrosswordBuildListModel } from '../../../../../../Common/model/crossword.build';
import { ComponentCommunicationService } from '../../../services/componentcommunication/component-communication.service';
import { CrosswordService } from '../../../services/crossword/crossword.service';
import { CrosswordBuildService } from '../../../services/crosswordbuild/crossword-build.service';
import { CrosswordGameService } from '../../../services/crosswordgame/crossword-game.service';
import { GenerateCrosswordService } from '../../../services/generatecrossword/generate-crossword.service';
import { UserService } from '../../../services/user/user.service';
import { LocalizedPaginatorIntl } from '../../build-crossword/select-cwquestions/localized.paginator';
import { FlexibleButtonInMenu, FlexibleButtonType } from '../../common/flexable-button-menu/model/flexible.button.in.menu';
import { DialogService } from '../../dialog/service/dialog.service';
import { CrosswordLandingHandleCrosswordView } from '../../util/handleCrosswordView/crossword.landing.handle.crossword.tiles';
import { ShowHandleCrosswordView } from '../../util/handleCrosswordView/show.handle.crossword.view';
import { SortColumn, SortColumnsUtil, SortOn, compare } from '../../util/sort.util';
import { WaitUtil } from '../../util/wait.util';

interface RandomCrosswordListItemUI extends CrosswordListItem {
  hovered?: boolean;
  typeAsString: string;
}

export interface SelectedRandomCrosswordBuildIds {
  selectedRandomId: FormControl<string>;
  selectedCaptchaRandomId: FormControl<string>;
}

@Component({
  selector: 'app-select-random-crosswords',
  templateUrl: './select-random-crosswords.component.html',
  styleUrls: ['./select-random-crosswords.component.scss'],
  providers: [{ provide: MatPaginatorIntl, useClass: LocalizedPaginatorIntl }],
})
export class SelectRandomCrosswordsComponent implements OnInit {
  crosswordLisItems = new MatTableDataSource<RandomCrosswordListItemUI>();

  dispayedColumns = ['type', 'date'];
  actionButtons: FlexibleButtonInMenu[] = [];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  loaded = false;
  allBuiltCrosswordListItems: RandomCrosswordListItemUI[];
  selectedCrosswordListItem: RandomCrosswordListItemUI;
  crosswordBuiltListItems: CrosswordBuildListModel[];
  selectedRandomCrosswordBuildIdForm: FormGroup<SelectedRandomCrosswordBuildIds>;
  private sortUtil: SortColumnsUtil<RandomCrosswordListItemUI>;
  readonly handleCrosswordView: ShowHandleCrosswordView;

  private currentSort: SortOn = { columnId: 'date', direction: 'desc' };
  constructor(
    private crosswordService: CrosswordService,
    private crosswordGameService: CrosswordGameService,
    private router: Router,
    private crosswordBuildService: CrosswordBuildService,
    componentCommunication: ComponentCommunicationService,
    private translateService: TranslateService,
    private dialogService: DialogService,
    private userService: UserService,
    private randomCrosswordService: GenerateCrosswordService,
  ) {
    componentCommunication.currentRouteTitle.next(translateService.instant('route-titles.list-crosswords'));
    this.handleCrosswordView = new CrosswordLandingHandleCrosswordView();
  }
  async ngOnInit() {
    await this.refreshCrosswordListItems();
    this.crosswordBuiltListItems = await this.crosswordBuildService.getListOfCrosswordBuilds(this.userService.getLoggedIn());
    this.populateSortItems();
    this.applySort();
    this.refreshActionButtons();
    WaitUtil.waitUntilSet(() => this.paginator).then(() => (this.crosswordLisItems.paginator = this.paginator));
    this.crosswordLisItems.filterPredicate = (item: RandomCrosswordListItemUI, filter: string) => {
      return item.typeAsString.toLowerCase().search(filter.toLowerCase()) > -1;
    };
    await this.initializeRandomCrosswordIds();
    this.loaded = true;
  }
  private async initializeRandomCrosswordIds() {
    const buildIs = await this.randomCrosswordService.getGenerateRandomCrosswordBuildIds();
    const selectedRandomId = this.crosswordBuiltListItems.find((c) => c.externalId === buildIs.randomCrosswordBuildId)?.externalId;
    const selectedCaptchaRandomId = this.crosswordBuiltListItems.find(
      (c) => c.externalId === buildIs.randomCaptchaCrosswordBuildId,
    )?.externalId;
    this.selectedRandomCrosswordBuildIdForm = new FormGroup<SelectedRandomCrosswordBuildIds>({
      selectedRandomId: new FormControl<string>(selectedRandomId, [Validators.required]),
      selectedCaptchaRandomId: new FormControl<string>(selectedCaptchaRandomId, [Validators.required]),
    });
  }

  private async refreshCrosswordListItems() {
    const allCrosswordListItems = await this.crosswordService.getRandomCrosswordListItems();
    this.allBuiltCrosswordListItems = allCrosswordListItems.map((c) => this.convertToUiItem(c));
    this.crosswordLisItems.data = this.allBuiltCrosswordListItems;
  }

  onSortChange(sort: Sort) {
    this.currentSort.columnId = sort.active;
    this.currentSort.direction = sort.direction;
    this.applySort();
    this.paginator?.firstPage();
  }
  async applyFilter(filter: string) {
    this.crosswordLisItems.filter = filter;
  }
  private convertToUiItem(item: CrosswordListItem): RandomCrosswordListItemUI {
    return {
      ...item,
      hovered: false,
      typeAsString: this.getTypeAsString(item),
    };
  }
  getTypeAsString(item: CrosswordListItem): string {
    switch (item.type) {
      case CrosswordType.Built:
        return this.translateService.instant('crossword-types.built');
      case CrosswordType.RandomCaptcha:
        return this.translateService.instant('crossword-types.captcha');
      case CrosswordType.Random:
        return this.translateService.instant('crossword-types.random');
      default:
        throw new Error('Unknown crossword type');
    }
  }
  async onCrosswordSelected(crosswordListItem: RandomCrosswordListItemUI) {
    this.selectedCrosswordListItem = crosswordListItem;
    this.refreshActionButtons();
    this.dialogService.wrapInProgress(async () => {
      const crossword = await this.crosswordService.getCrosswordByExternalId(crosswordListItem.externalId);
      this.handleCrosswordView.refresh(crossword);
    });
  }
  async onSelectFilteredValuesChanged(filteredCrosswordListItems: RandomCrosswordListItemUI[]) {
    this.crosswordLisItems.data = filteredCrosswordListItems;
    this.applySort();
    this.paginator?.firstPage();
  }
  async onRandomCrosswordBuildSelected() {
    await this.dialogService.wrapInProgress(async () => {
      const selectedRandomId = this.selectedRandomCrosswordBuildIdForm.get('selectedRandomId').value;
      const selectedCaptchaRandomId = this.selectedRandomCrosswordBuildIdForm.get('selectedCaptchaRandomId').value;
      await this.randomCrosswordService.setGenerateRandomCrosswordBuildIds({
        randomCaptchaCrosswordBuildId: selectedCaptchaRandomId,
        randomCrosswordBuildId: selectedRandomId,
      });
    });
  }
  private applySort() {
    this.crosswordLisItems.data = this.sortUtil.sortItem(this.currentSort, this.crosswordLisItems.data);
  }
  private refreshActionButtons() {
    this.actionButtons = [
      {
        id: 'delete-crossword',
        text: this.translateService.instant('buttons.delete-crossword'),
        icon: 'shuffle',
        type: FlexibleButtonType.Standard,
        disabled: !this.selectedCrosswordListItem,
        standardAction: {
          event: () => this.onDeleteSelectedCrossword(),
        },
      },
    ];
  }
  private async onDeleteSelectedCrossword() {
    if (this.selectedCrosswordListItem) {
      this.dialogService.wrapInProgress(async () => {
        await this.crosswordService.deleteRandomCrossword(this.selectedCrosswordListItem._id);
        await this.refreshCrosswordListItems();
        this.selectedCrosswordListItem = null;
        this.refreshActionButtons();
      });
    }
  }
  private populateSortItems() {
    this.sortUtil = new SortColumnsUtil([
      new SortColumn('typeAsString', (t1, t2, isAsc) => compare(t1.typeAsString, t2.typeAsString, isAsc)),
      new SortColumn('date', (t1, t2, isAsc) => compare(t1.date, t2.date, isAsc)),
    ]);
  }
}
