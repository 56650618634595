import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, lastValueFrom } from 'rxjs';
import { GenerateImage, GenerateImageResponse } from '../../../../../Common/data/generate.images';
import { ImageSearch, ImageSearchResult } from '../../../../../Common/data/images.search';
import { LanguageUtil } from '../../../../../Common/util/language.util';
import { AppSettingsService } from '../appsettings/app-settings.service';
import { ConfigService } from '../config/config.service';

@Injectable({
  providedIn: 'root',
})
export class ImageService {
  constructor(
    private configService: ConfigService,
    private httpClient: HttpClient,
    private appSettingsService: AppSettingsService,
    private translateService: TranslateService,
  ) {}
  async searchForImages(search: string) {
    const routes = this.configService.getRoutes();
    const searchImages: ImageSearch = {
      languageCode: LanguageUtil.getLanguageCode(this.translateService.getBrowserLang()),
      search: search,
    };
    return await lastValueFrom(
      this.httpClient.post<ImageSearchResult[]>(`${this.appSettingsService.appSettings.apiurl}${routes.images}/search`, searchImages),
    );
  }
  getImage(url: string): Observable<Blob> {
    return this.httpClient.get(url, { responseType: 'blob' });
  }
  getImageUrl(imageId: string): any {
    const routes = this.configService.getRoutes();
    return `${this.appSettingsService.appSettings.apiurl}${routes.images}/load/${imageId}`;
  }
  async generateImage(generateText: string) {
    const routes = this.configService.getRoutes();
    const generateImage: GenerateImage = {
      languageCode: LanguageUtil.getLanguageCode(this.translateService.getBrowserLang()),
      generateText: generateText,
    };
    return await lastValueFrom(
      this.httpClient.post<GenerateImageResponse>(`${this.appSettingsService.appSettings.apiurl}${routes.images}/generate`, generateImage),
    );
  }
}
