import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ImageSearchResult } from '../../../../../../../Common/data/images.search';
import { ImageService } from '../../../../services/image/image.service';
import { DialogService } from '../../../dialog/service/dialog.service';
import { SizeChanged } from '../../../util/resize.directive';

export interface SearchImagesForm {
  search: AbstractControl<string>;
  selectedImage: AbstractControl<ImageSearchResult>;
}

export interface SearchImagesData {
  hasCurrentImage: boolean;
  answer: string;
}

export enum ImageResultType {
  Ok,
  Cancel,
}

export interface SearchImagesResult {
  type: ImageResultType;
  imageUrl?: string;
}

@Component({
  selector: 'app-search-images',
  templateUrl: './search-images.component.html',
  styleUrls: ['./search-images.component.scss'],
})
export class SearchImagesComponent {
  imageSearchResult: ImageSearchResult[] = [];
  searchImages: FormGroup<SearchImagesForm>;
  columnCount = 2;
  searched = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: SearchImagesData,
    private matDialogRef: MatDialogRef<SearchImagesComponent>,
    private imageService: ImageService,
    private changeDetection: ChangeDetectorRef,
    private dialogService: DialogService,
  ) {
    this.searchImages = new FormGroup<SearchImagesForm>({
      search: new FormControl(data.answer?.toLowerCase()),
      selectedImage: new FormControl(null, Validators.required),
    });
    this.searchImages.controls.search.valueChanges.subscribe(() => {
      this.searched = false;
    });
    if (data.answer) {
      this.onSearchImages();
    }
  }

  async onSearchImages() {
    const search = this.searchImages.value.search;
    this.imageSearchResult = await this.dialogService.wrapInProgress(() => this.imageService.searchForImages(search));
    this.searched = true;
  }

  onSelectImage() {
    const value = this.searchImages.value;
    this.closeDialogWithResult({ type: ImageResultType.Ok, imageUrl: value.selectedImage.smallImageUrl });
  }

  onImageClicked(image: ImageSearchResult) {
    this.searchImages.controls.selectedImage.setValue(image);
  }

  onCancel() {
    this.matDialogRef.close({ type: ImageResultType.Cancel });
  }

  private closeDialogWithResult(result: SearchImagesResult) {
    this.matDialogRef.close(result);
  }

  onSizeChanged(size: SizeChanged) {
    this.columnCount = size.width > 600 ? 4 : 2;
    this.changeDetection.detectChanges();
  }
}
