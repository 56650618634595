@if (loaded) {
  <div fxLayout="column" fxLayoutGap="10px" style="padding: 10px">
    <div fxLayout.lt-sm="column" fxLayout.gt-xs="row" fxLayoutGap="10px">
      <mat-form-field fxFlex>
        <mat-label>{{ 'labels.filter-crosswords' | translate }}</mat-label>
        <input
          #filterInput
          matInput
          [placeholder]="'labels.filter-crosswords' | translate"
          (keyup)="applyFilter(filterInput.value)"
          autocomplete="off"
        />
      </mat-form-field>
    </div>
    <div
      fxLayout.lt-sm="column"
      fxLayout.gt-xs="row"
      fxLayoutGap="10px"
      form
      [formGroup]="selectedRandomCrosswordBuildIdForm"
      fxLayoutAlign.gt-xs="start center"
    >
      <mat-form-field>
        <mat-label>{{ 'labels.select-random-crossword-build' | translate }}</mat-label>
        <mat-select formControlName="selectedRandomId">
          @for (crosswordBuild of crosswordBuiltListItems; track crosswordBuild) {
            <mat-option [value]="crosswordBuild.externalId">
              {{ crosswordBuild.name }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'labels.select-random-captcha-crossword-build' | translate }}</mat-label>
        <mat-select formControlName="selectedCaptchaRandomId">
          @for (crosswordBuild of crosswordBuiltListItems; track crosswordBuild) {
            <mat-option [value]="crosswordBuild.externalId">
              {{ crosswordBuild.name }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
      <button
        mat-raised-button
        color="primary"
        (click)="onRandomCrosswordBuildSelected()"
        [disabled]="selectedRandomCrosswordBuildIdForm.invalid"
      >
        {{ 'buttons.select-random-crosswordbuild' | translate }}
      </button>
    </div>
    <mat-table
      test-id="list-crosswords-table"
      #table
      matSort
      [dataSource]="crosswordLisItems"
      (matSortChange)="onSortChange($event)"
      matSortActive="name"
      matSortDirection="asc"
    >
      <ng-container matColumnDef="date">
        <mat-header-cell *matHeaderCellDef class="w-150" mat-sort-header>{{ 'columns.published' | translate }}</mat-header-cell>
        <mat-cell class="w-150" *matCellDef="let crossword"> {{ crossword.date | date: 'yyyy-MM-dd' }} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="type">
        <mat-header-cell *matHeaderCellDef class="w-150" mat-sort-header>{{ 'columns.type' | translate }}</mat-header-cell>
        <mat-cell class="w-150" *matCellDef="let crossword"> {{ crossword.typeAsString }} </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="dispayedColumns"></mat-header-row>
      <mat-row
        *matRowDef="let crossword; columns: dispayedColumns"
        [id]="'crossword-' + crossword.id"
        [ngClass]="{ hovered: crossword.hovered, selected: crossword == selectedCrosswordListItem }"
        (click)="onCrosswordSelected(crossword)"
        (mouseover)="crossword.hovered = true"
        (mouseout)="crossword.hovered = false"
        (blur)="crossword.hovered = false"
        (focus)="crossword.hovered = true"
      >
      </mat-row>
      <mat-paginator
        [ngStyle]="{ display: !loaded || crosswordLisItems.data.length == 0 ? 'none' : 'block' }"
        [pageSizeOptions]="[10, 50, 100]"
      ></mat-paginator>
    </mat-table>
    @if (handleCrosswordView.isCrosswordSet()) {
      <app-flexable-button-menu fxFlex [buttons]="actionButtons"> </app-flexable-button-menu>
      <div>
        <app-show-crossword [handleCrosswordView]="handleCrosswordView"></app-show-crossword>
      </div>
    }
  </div>
}
@if (!loaded) {
  <app-progress-bar></app-progress-bar>
}
