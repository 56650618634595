import { Component, Inject } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ImageService } from '../../../../services/image/image.service';
import { DialogService } from '../../../dialog/service/dialog.service';

export interface GenerateImagesForm {
  generateText: AbstractControl<string>;
  imageUrl: AbstractControl<string>;
}

export interface GenerateImagesData {
  hasCurrentImage: boolean;
}

export interface GenerateImagesResult {
  type: ImageResultType;
  imageUrl?: string;
}

export enum ImageResultType {
  Ok,
  Cancel,
}

@Component({
  selector: 'app-generate-image',
  templateUrl: './generate-image.component.html',
})
export class GenerateImageComponent {
  generateImage: FormGroup<GenerateImagesForm>;
  generated = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: GenerateImagesData,
    private matDialogRef: MatDialogRef<GenerateImageComponent>,
    private imageService: ImageService,
    private dialogService: DialogService,
    private translateService: TranslateService,
  ) {
    this.generateImage = new FormGroup<GenerateImagesForm>({
      generateText: new FormControl(null),
      imageUrl: new FormControl(null, [Validators.required]),
    });
    this.generateImage.controls.generateText.valueChanges.subscribe(() => {
      this.generated = false;
    });
  }

  async onGenerateImages() {
    const generate = this.generateImage.value.generateText;
    const generateImageResult = await this.dialogService.wrapInProgress(
      () => this.imageService.generateImage(generate),
      this.translateService.instant('messages.generate-image'),
    );
    this.generateImage.controls.imageUrl.setValue(generateImageResult.imageUrl);
    this.generated = true;
  }

  onSelectImage() {
    const imageUrl = this.generateImage.value.imageUrl;
    this.matDialogRef.close({ type: ImageResultType.Ok, imageUrl: imageUrl });
  }

  onCancel() {
    this.matDialogRef.close({ type: ImageResultType.Cancel });
  }
}
