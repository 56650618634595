import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { CheckLoggedIn } from './check.loggedin';

@Injectable()
export class CheckLoggedInAdministrator extends CheckLoggedIn {
  async canActivate(route: ActivatedRouteSnapshot) {
    const activate = await super.canActivate(route);
    if (!activate) {
      return false;
    }
    const settings = this.userService.getPlayerSettings();
    return settings?.administrator;
  }
}
